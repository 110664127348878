import React from "react";
import styled from "styled-components";

export default function Cloud() {
  return (
    <Section>
      <div className="cloud_one"></div>
    </Section>
  );
}

const Section = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: left;
  position: relative;
  width: 100%;
  height: auto;
  z-index: 2;
  margin: 200px 0 50px 150px;
  /* border: 1px solid grey; */

  .cloud_one {
    position: absolute;
    width: 100px;
    height: 50px;
    border: 1px solid red;
    display: flex;
    bottom: 0;
    border-radius: 80px 80px 0 0;
    z-index: 2;
  }

  .cloud_one::before {
    content: "";
    position: absolute;
    z-index: 2;
    display: flex;
    width: 50px;
    height: 25px;
    border: 1px solid blue;
    /* bottom: ; */
    /* top: 0; */
    bottom: 0;
    left: -50px;
    border-radius: 80px 80px 0 0;
  }
  .cloud_one::after {
    content: "";
    position: absolute;
    z-index: 2;
    display: flex;
    width: 30px;
    height: 15px;
    border: 1px solid green;
    /* bottom: ; */
    /* top: 0; */
    right: -30px;
    bottom: 0;
    border-radius: 80px 80px 0 0;
  }
`;
